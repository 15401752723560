import { getCurrentBasket } from "@redux/localStorage/basket"
import { buildPlateImageUrlFromBasket, getFrontPlateWidth } from "@utils/Helpers"
import React, { SyntheticEvent } from "react"
import { Form, Grid, Input, Responsive, } from "semantic-ui-react"
import { Checkout } from "@models/Checkout/Checkout"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { Formik } from "formik"
import { connect as formikConnect } from "formik"
import { KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { navigate } from "gatsby"
import { CheckoutSelectionCardModel } from "@models/Checkout/CheckoutSelectionCard"
import { CheckoutSelectionCard } from "@components/CheckoutSelectionCard"
import { giftingBoxOptionValidationSchema, giftingOptionValidationSchema } from "@components/Mobile/GiftingSelection/GiftingOption.validationSchema"
import { CountsStyle, FormLabel } from "@components/Mobile/GiftingSelection/EcardContainer.style"
import { AddressSearchAutoComplete, AddressSearchTitleSection, PersonalDetailsContainerLink, PersonalDetailsManualAddressContainerHeading } from "@components/Dealer/OwnerDetailsForm.style"
import { Legal } from "@styles/Global.styles"
import { AddressFinderService } from "@services/AddressFinderService"
import { addressResultRender } from "@components/Dealer/OwnerDetailsForm"
import _ from "lodash"
import { GiftImageFlipCard } from "./GiftImageFlipCard"
import { AddressFinderFormGroup, AddressToggerLink, GiftTypeLeftColumn, GiftTypeRightColumn, PersonalDetailsManualAddressContainer } from "./GiftPage.styles"

export type Props = {
    apiUrl: string
    updatePersonalDetails: (personalDetails: Checkout) => void
    checkout: Checkout
    addressValidationToken: string
    frontImage: string
    backImage: string
}

export type States = {
    manualAddress: boolean
    searchingAddress: boolean
    addressResults: any[]
    addressValue: string
    to: string
    from: string
    message: string
    isForMe: boolean
    counts: number
}

function OnSubmitValidationError(props: any) {
    const { callback, formik } = props
  
    const effect = () => {
      if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
        callback(formik)
      }
    }
    React.useEffect(effect, [formik.submitCount, formik.isSubmitting])
  
    return null
  }
  
export const SubmitValidationError = formikConnect(OnSubmitValidationError)

class GiftboxContainer extends React.Component<Props, States>{

    constructor(props: Props){
        super(props)
        this.state ={
            manualAddress: this.props.checkout.giftingDetails.giftboxDeliveryAddress?.isManualAddress || false,
            searchingAddress: false,
            addressResults: [],
            addressValue: this.props.checkout.giftingDetails.giftboxDeliveryAddress?.fullAddress || "",
            to: this.props.checkout.giftingDetails.to,
            from: this.props.checkout.giftingDetails.from,
            message: this.props.checkout.giftingDetails.message,
            isForMe: this.props.checkout.giftingDetails.isForMe,
            counts: this.props.checkout.giftingDetails.message.length
        }
    }

    intialStatus = {
        isSubmitting: false,
        isSubmitted: false,
      }
      
    debouncedFn: any
    handleAddressSearchChange = (e: SyntheticEvent, data: any) => {
        this.setState({ addressValue: data.value })
        if (!this.debouncedFn) {
            this.debouncedFn = _.debounce(this.searchAddress, 500)
        }
        this.debouncedFn()
    }

    searchAddress = () => {
        const input = this.state.addressValue
        this.setState({ searchingAddress: true })

        const token = this.props.addressValidationToken
        AddressFinderService.experianAddressSearch(token, input).then(
            (result: any) => {
                const matchedAddresses = result.map((item: any) => ({
                    title: item.text,
                    id: item.global_address_key
                }))
                this.setState({
                    addressResults: matchedAddresses,
                    searchingAddress: false
                })
            }
        )
    }

      toggleManualAddress = (setFieldValue: any) => {
        const isManualAddress = !this.state.manualAddress
        
        this.setState({ manualAddress: isManualAddress })
        setFieldValue("giftingDetails.giftboxDeliveryAddress.isManualAddress", isManualAddress)
        setFieldValue("giftingDetails.giftboxDeliveryAddress.fullAddress", "")
        setFieldValue("giftingDetails.giftboxDeliveryAddress.unit", "")
        setFieldValue("giftingDetails.giftboxDeliveryAddress.city", "")
        setFieldValue("giftingDetails.giftboxDeliveryAddress.postCode", "")
        setFieldValue("giftingDetails.giftboxDeliveryAddress.street", "")
        setFieldValue("giftingDetails.giftboxDeliveryAddress.suburb", "")
        this.setState({
            addressValue: "",
        })

        const giftboxDeliveryAddress = this.props.checkout.giftingDetails.giftboxDeliveryAddress
        if(giftboxDeliveryAddress){
            giftboxDeliveryAddress.isManualAddress = isManualAddress
            // this.props.updateOwnerAddress(giftboxDeliveryAddress)
        }
    }
    submitForm = (values:any, actions: any) =>{
        if(this.state.from && this.state.to && this.state.message){
            this.props.updatePersonalDetails(values)

            navigate('/personal-details');
        }
        
    }

    checkFormValidation = () => {
        if(this.state.from.trim() && this.state.to.trim() && this.state.message.trim()){
            return false;
        }
        return true;
    }

    onSubmitValidationError = (event: any) => {
        console.log("error on validate submission")
        console.log(event)
    }

    selectIsForMe = (isForMe : boolean, setFieldValue: any) => {
        setFieldValue("giftingDetails.isForMe", isForMe)
        this.setState({isForMe: isForMe})
    }
    textAreaAdjust = () => {
        if(document) {
            var element = document.getElementById("giftingDetails.message")
            if(element){
                element.style.height = "1px";
                element.style.height = (5+element.scrollHeight)+"px";
            }
        }        
    }

    render() {
        var myselfCard: CheckoutSelectionCardModel = {
            body: "",
            content: "",
            heading: "Myself",
            items: [],
            icon:[]
          };
      
          var ownerCard: CheckoutSelectionCardModel = {
            body: "",
            content: "",
            heading: "Giftee (Owner)",
            items: [],
            icon:[]
          };

        const checkout = getCurrentCheckout();
        const basketLine = getCurrentBasket().basketLines[0];
        const frontPlateWidth = getFrontPlateWidth(basketLine.frontPlate.size.width_mm, basketLine.backPlate.size.width_mm);
        const frontPlateUrl = buildPlateImageUrlFromBasket(this.props.apiUrl, basketLine.frontPlate, basketLine.combinationLayout, basketLine.topCaption, basketLine.bottomCaption);

        return(
            <Formik
                initialValues={checkout}
                initialStatus={this.intialStatus}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={giftingBoxOptionValidationSchema}
                onSubmit={(values: any, actions: any) =>
                    this.submitForm(values, actions)
                }>
            {({
                errors,
                values,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isValidating,
                validateField,
                isValid,
                dirty,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <SubmitValidationError callback={this.onSubmitValidationError} />
                    <Grid direction="row">                    
                        <Grid.Column mobile={16} tablet={16} computer={7}>
                            <GiftImageFlipCard
                                frontImage={<img src={this.props.frontImage} alt="Gift Box" style={{ width: "100%"}} />}
                                backImage={<img src={this.props.backImage} alt="Gift Box Certificate" style={{ width: "100%"}} />}
                                frontText="View Gift Certificate"
                                backText="Return"
                            />
                        </Grid.Column>                        
                        <Grid.Column mobile={16} tablet={16} computer={9}>   
                            <React.Fragment>                                
                                <Form.Group widths="equal">
                                    <KpFormField
                                        width={7}
                                        error={
                                        errors.giftingDetails?.to &&
                                        touched.giftingDetails?.to
                                        }
                                    >
                                        <label>TO</label>
                                        {errors.giftingDetails?.to &&
                                        touched.giftingDetails?.to && (
                                            <KpFormInputErrorMessage>
                                            {errors.giftingDetails.to}
                                            </KpFormInputErrorMessage>
                                        )}
                                        <Input
                                        id="giftingDetails.to"
                                        name="giftingDetails.to"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="To"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onKeyUp={(event: { currentTarget: { value: any } })=>{this.setState({to: event.currentTarget.value})}}
                                        onBlur={handleBlur}
                                        value={values.giftingDetails.to}
                                        placeholder="Type here..."
                                        autoComplete="off"/>
                                    </KpFormField>
                                    <KpFormField
                                        width={7}
                                        error={
                                        errors.giftingDetails?.from &&
                                        touched.giftingDetails?.from
                                        }
                                    >
                                        <label>FROM</label>
                                        {errors.giftingDetails?.from &&
                                        touched.giftingDetails?.from && (
                                            <KpFormInputErrorMessage>
                                            {errors.giftingDetails.from}
                                            </KpFormInputErrorMessage>
                                        )}
                                        <input
                                        id="giftingDetails.from"
                                        name="giftingDetails.from"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="From"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={(event)=>{this.setState({from: event.currentTarget.value})}}
                                        value={values.giftingDetails.from}
                                        placeholder="Type here..."
                                        autoComplete="off"/>
                                    </KpFormField> 
                                </Form.Group>
                                <Form.Group widths="equal"> 
                                    <KpFormField
                                        error={
                                        errors.giftingDetails?.message &&
                                        touched.giftingDetails?.message
                                        }
                                    >
                                        <label>MESSAGE</label>
                                        {errors.giftingDetails?.message &&
                                        touched.giftingDetails?.message && (
                                            <KpFormInputErrorMessage>
                                            {errors.giftingDetails?.message}
                                            </KpFormInputErrorMessage>
                                        )}
                                        <textarea
                                        id="giftingDetails.message"
                                        name="giftingDetails.message"
                                        data-private="lipsum"
                                        aria-label="Message"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={(event)=>{this.setState({message: event.currentTarget.value, counts: event.currentTarget.value.length}); this.textAreaAdjust()}}
                                        value={values.giftingDetails?.message}
                                        placeholder="Type here..."
                                        autoComplete="off"/>
                                        <CountsStyle>{this.state.counts + '/500'}</CountsStyle>
                                    </KpFormField> 
                                </Form.Group>       
                                <Form.Group widths="equal">  
                                    <KpFormField
                                        width={8}
                                        error={
                                        errors.giftingDetails?.isForMe &&
                                        touched.giftingDetails?.isForMe
                                        }
                                    >
                                        <FormLabel><label>Deliver this Gift Box directly to:</label></FormLabel>
                                        {errors.giftingDetails?.isForMe &&
                                        touched.giftingDetails?.isForMe && (
                                            <KpFormInputErrorMessage>
                                            {errors.giftingDetails.isForMe}
                                            </KpFormInputErrorMessage>
                                        )}
                                        <Grid  stackable={false} stretched>
                                            <Grid.Row>
                                                <GiftTypeLeftColumn width={8}>
                                                    <CheckoutSelectionCard card={myselfCard} onSelect={() => this.selectIsForMe(true, setFieldValue)} isSelected={this.state.isForMe} hideButton={true}/>
                                                </GiftTypeLeftColumn>
                                                <GiftTypeRightColumn width={8}>
                                                    <CheckoutSelectionCard card={ownerCard} onSelect={() => this.selectIsForMe(false, setFieldValue)} isSelected={!this.state.isForMe} hideButton={true}/>
                                                </GiftTypeRightColumn>      
                                            </Grid.Row>
                                        </Grid>                                          
                                    </KpFormField>
                                </Form.Group>   

                            
                                            <AddressFinderFormGroup
                                                show={!this.state.manualAddress}
                                                widths="equal"
                                            >
                                                <KpFormField
                                                    error={errors.giftingDetails?.giftboxDeliveryAddress && touched.giftingDetails?.giftboxDeliveryAddress &&
                                                        touched.giftingDetails?.giftboxDeliveryAddress.fullAddress}
                                                >
                                                    <AddressSearchTitleSection> 
                                                        <label>ADDRESS</label>
                                                        <AddressToggerLink
                                                            onClick={() => this.toggleManualAddress(setFieldValue)}
                                                        >
                                                            ENTER ADDRESS MANUALLY
                                                        </AddressToggerLink>
                                                        {this.props.checkout.giftingDetails.giftboxDeliveryAddress &&
                                                            errors.giftingDetails?.giftboxDeliveryAddress &&
                                                            touched.giftingDetails?.giftboxDeliveryAddress && (
                                                                <KpFormInputErrorMessage>
                                                                    Please select an address
                                                                </KpFormInputErrorMessage>
                                                            )}
                                                    </AddressSearchTitleSection>

                                                    <AddressSearchAutoComplete
                                                        id="giftingDetails.giftboxDeliveryAddress.fullAddress"
                                                        autoComplete="new-autocomplete-gift-address"
                                                        // loading={this.state.searchingAddress}
                                                        name="giftingDetails.giftboxDeliveryAddress.fullAddress"
                                                        type="text"
                                                        aria-label="Full Addr"
                                                        disabled={status.isSubmitting}
                                                        minCharacters={2}
                                                        onSearchChange={(
                                                            event: SyntheticEvent,
                                                            data: any
                                                        ) => {
                                                            setFieldValue(
                                                                "giftingDetails.giftboxDeliveryAddress.fullAddress",
                                                                data.value
                                                            );
                                                            setFieldValue("giftingDetails.giftboxDeliveryAddress.unit", "");
                                                            setFieldValue("giftingDetails.giftboxDeliveryAddress.city", "");
                                                            setFieldValue("giftingDetails.giftboxDeliveryAddress.postCode", "");
                                                            setFieldValue("giftingDetails.giftboxDeliveryAddress.street", "");
                                                            setFieldValue("giftingDetails.giftboxDeliveryAddress.suburb", "");
                                                            this.handleAddressSearchChange(event, data);
                                                        }}
                                                        onResultSelect={(
                                                            event: SyntheticEvent,
                                                            data: any
                                                        ) => {
                                                            this.setState({
                                                                addressValue: data.result.title,
                                                                searchingAddress: true,
                                                            });
                                                            setFieldValue(
                                                                "giftingDetails.giftboxDeliveryAddress.fullAddress",
                                                                data.result.title
                                                            );
                                                            const token = this.props.addressValidationToken;
                                                            AddressFinderService.experianAddressMetadata(
                                                                token,
                                                                data.result.id
                                                            ).then((result: any) => {
                                                                const unit = result.address_line_2
                                                                    ? result.address_line_1
                                                                    : "";
                                                                const addressLine1 = result.address_line_2
                                                                    ? result.address_line_2
                                                                    : result.address_line_1;

                                                                setFieldValue("giftingDetails.giftboxDeliveryAddress.unit", unit);
                                                                setFieldValue(
                                                                    "giftingDetails.giftboxDeliveryAddress.city",
                                                                    result.region
                                                                );
                                                                setFieldValue(
                                                                    "giftingDetails.giftboxDeliveryAddress.postCode",
                                                                    result.postal_code
                                                                );
                                                                setFieldValue(
                                                                    "giftingDetails.giftboxDeliveryAddress.street",
                                                                    addressLine1
                                                                );
                                                                setFieldValue(
                                                                    "giftingDetails.giftboxDeliveryAddress.suburb",
                                                                    result.locality
                                                                );
                                                                this.setState({
                                                                    addressValue: data.result.title,
                                                                    searchingAddress: false,
                                                                });
                                                            });
                                                        }}
                                                        showNoResults={false}
                                                        value={this.state.addressValue}
                                                        results={this.state.addressResults}
                                                        resultRenderer={addressResultRender} />
                                                </KpFormField>
                                            </AddressFinderFormGroup>

                                            {this.state.manualAddress  && (
                                                    <PersonalDetailsManualAddressContainer>
                                                        <Grid>
                                                            <Grid.Column textAlign="left" width={8}>
                                                                <PersonalDetailsManualAddressContainerHeading>
                                                                    Manual Address Input
                                                                </PersonalDetailsManualAddressContainerHeading>
                                                                <Legal color={theme.brand.colors.blue}>
                                                                    {" "}
                                                                </Legal>
                                                            </Grid.Column>
                                                            <Grid.Column textAlign="right" width={8}>
                                                                <AddressToggerLink
                                                                    onClick={() => this.toggleManualAddress(setFieldValue)}
                                                                >
                                                                    USE ADDRESS LOOKUP
                                                                </AddressToggerLink>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Form.Group widths="equal">
                                                            <KpFormField
                                                                error={errors.giftingDetails?.giftboxDeliveryAddress?.building &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.building}
                                                            >
                                                                <label>BUILDING NAME</label>
                                                                {errors.giftingDetails?.giftboxDeliveryAddress?.building &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.building && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.giftingDetails?.giftboxDeliveryAddress?.building}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="giftingDetails.giftboxDeliveryAddress.building"
                                                                    name="giftingDetails.giftboxDeliveryAddress.building"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Delivery Address - building"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.giftingDetails?.giftboxDeliveryAddress?.building}
                                                                ></input>
                                                            </KpFormField>
                                                            <KpFormField
                                                                error={errors.giftingDetails?.giftboxDeliveryAddress?.unit &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.unit}
                                                            >
                                                                <label>UNIT #, FLOOR, LEVEL</label>
                                                                {errors.giftingDetails?.giftboxDeliveryAddress?.unit &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.unit && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.giftingDetails?.giftboxDeliveryAddress?.unit}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="giftingDetails.giftboxDeliveryAddress.unit"
                                                                    name="giftingDetails.giftboxDeliveryAddress.unit"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Delivery Address - unit"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.giftingDetails?.giftboxDeliveryAddress?.unit}
                                                                ></input>
                                                            </KpFormField>
                                                        </Form.Group>
                                                        <Form.Group widths="equal">
                                                            <KpFormField
                                                                error={errors.giftingDetails?.giftboxDeliveryAddress?.street &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.street}
                                                            >
                                                                <label>STREET ADDRESS</label>
                                                                {errors.giftingDetails?.giftboxDeliveryAddress?.street &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.street && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.giftingDetails?.giftboxDeliveryAddress?.street}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="giftingDetails.giftboxDeliveryAddress.street"
                                                                    name="giftingDetails.giftboxDeliveryAddress.street"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Delivery Address - street"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.giftingDetails?.giftboxDeliveryAddress?.street}
                                                                ></input>
                                                            </KpFormField>
                                                            <KpFormField
                                                                error={errors.giftingDetails?.giftboxDeliveryAddress?.suburb &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.suburb}
                                                            >
                                                                <label>SUBURB</label>
                                                                {errors.giftingDetails?.giftboxDeliveryAddress?.suburb &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.suburb && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.giftingDetails?.giftboxDeliveryAddress?.suburb}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="giftingDetails.giftboxDeliveryAddress.suburb"
                                                                    name="giftingDetails.giftboxDeliveryAddress.suburb"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Delivery Address - suburb"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.giftingDetails?.giftboxDeliveryAddress?.suburb}
                                                                ></input>
                                                            </KpFormField>
                                                        </Form.Group>
                                                        <Form.Group widths="equal">
                                                            <KpFormField
                                                                error={errors.giftingDetails?.giftboxDeliveryAddress?.city &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.city}
                                                            >
                                                                <label>CITY OR TOWN</label>
                                                                {errors.giftingDetails?.giftboxDeliveryAddress?.city &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.city && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.giftingDetails?.giftboxDeliveryAddress?.city}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="giftingDetails.giftboxDeliveryAddress.city"
                                                                    name="giftingDetails.giftboxDeliveryAddress.city"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Delivery Address - city"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.giftingDetails?.giftboxDeliveryAddress?.city}
                                                                ></input>
                                                            </KpFormField>
                                                            <KpFormField
                                                                error={errors.giftingDetails?.giftboxDeliveryAddress?.postCode &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.postCode}
                                                            >
                                                                <label>POSTCODE</label>
                                                                {errors.giftingDetails?.giftboxDeliveryAddress?.postCode &&
                                                                    touched.giftingDetails?.giftboxDeliveryAddress?.postCode && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.giftingDetails?.giftboxDeliveryAddress?.postCode}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="giftingDetails.giftboxDeliveryAddress.postCode"
                                                                    name="giftingDetails.giftboxDeliveryAddress.postCode"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Delivery Address - postcode"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.giftingDetails?.giftboxDeliveryAddress?.postCode}
                                                                ></input>
                                                            </KpFormField>
                                                        </Form.Group>
                                                    </PersonalDetailsManualAddressContainer>
                                      
                                    )}
                            </React.Fragment>
                        </Grid.Column>
                    </Grid>   
                    <Grid.Row verticalAlign='bottom'>
                        <Responsive minWidth={theme.responsive.tablet.minWidth}>
                            <Grid.Column width={16}>
                                <Grid reversed='mobile vertically'>                            
                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <KpButton id="pos-back" fullWidth="mobile"  buttonType='secondary' color={theme.brand.colors.black} link='/plate-owner-selection/'>BACK</KpButton>
                                    </Grid.Column>                            
                                    <Grid.Column mobile={16} tablet={8} computer={8} textAlign='right'>
                                    <KpButton id="pos-continue" fullWidth="mobile"  buttonType='primary' color={theme.brand.colors.blue} type="submit" disabled={this.checkFormValidation()}>CONTINUE</KpButton>
                                    </Grid.Column>                            
                                </Grid>
                            </Grid.Column>
                        </Responsive>
                        <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                            <Grid.Column width={16}>
                                <Grid reversed='mobile vertically'> 
                                    <Grid.Column mobile={16} textAlign='right'>
                                    <KpButton id="pos-continue" fullWidth="mobile"  buttonType='primary' color={theme.brand.colors.blue} type="submit" disabled={this.checkFormValidation()}>CONTINUE</KpButton>
                                    </Grid.Column>                            
                                </Grid>
                            </Grid.Column>
                        </Responsive>
                    </Grid.Row>                 
                </Form>
                )}
            </Formik>
        )
    }
    
}

export default GiftboxContainer