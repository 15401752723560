import styled from "@emotion/styled";
import mq from "@styles/mq";

export const ImageText= styled.span`
    vertical-align:text-bottom;
    padding-left: 5px;
    ${mq({        
        fontSize:['12px','14px','16px']
      })};
`

