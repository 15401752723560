import React, { useState } from "react"
import { graphql } from "gatsby";
import { Layout } from "@components/Layout";
import { mapToSEO, SEO } from "@components/SEO";
import { Grid } from "semantic-ui-react";
import OrderSummary from "@components/OrderSummary/OrderSummary";
import theme from "@styles/theme";
import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from "redux"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector";
import { updateManufactureNow} from "@redux/actions";
import { updateGiftingType, updateIsGifting, updateOwnerIsApplicant, updatePersonalDetails } from "@redux/actions/checkout";
import { currentBasketSelector } from "@redux/selectors/basketSelector";
import { isMobile } from "@utils/Helpers";
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader";
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer";
import { Checkout } from "@models/Checkout/Checkout";
import { TransactionTypesSetting } from "@models/ProductModels";
import { getCurrentBasket } from "@redux/localStorage/basket";
import GiftTypeSelection from "@components/GiftType/GiftType";
import { mapToGiftInfo } from "@mappers/Gifting/Gfiting.mapper";
import { getCurrentCheckout } from "@redux/localStorage/checkout";

const SelectGiftingTypePageContainer = (props:any) => {

    const {elements} = props.data.allKontentItemGiftSelectionPage.nodes.find((node: any) => {return node.elements.url.value == '/select-gifting-type/'});
    const apiUrl = props.data.site.siteMetadata.apiUrl;
    const seo = mapToSEO(elements);
    const giftboxInfo = mapToGiftInfo(elements.giftbox.linked_items[0].elements);
    const eCardInfo = mapToGiftInfo(elements.e_card.linked_items[0].elements);
    const checkout = getCurrentCheckout()
    const [giftType, setGiftType] = useState(checkout ? checkout.giftingType : 0);
    const transactionTypeId = getCurrentBasket().basketLines.length > 0 ? getCurrentBasket().basketLines[0].transactionTypeId : TransactionTypesSetting.New.id;
    
    const addressValidationToken = props.data.site.siteMetadata.addressValidationToken;
    if(isMobile() === undefined){
      return null;
    }   

    return (
    <Layout version="simple" currentStep={3} location={props.location}>
        <SEO {...seo} />
        <MobileGenericBannerContainer
          padding={{
            mobile: {
                top: 20,
                bottom: 40
            },
            desktop: {
                top: 40,
                bottom: 60
            }
          }}
          backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige}>
          <Grid stackable reversed='mobile tablet vertically' stretched>
                  <Grid.Column mobile={16} tablet={16} computer={10}>
                    <GiftTypeSelection  
                      heading={elements.heading.value}
                      subHeading={elements.sub_heading.value}
                      giftBoxInfo={giftboxInfo}
                      eCardInfo={eCardInfo}
                      updateOwnerIsApplicant={props.updateOwnerIsApplicant}
                      updateManufactureNow={props.updateManufactureNow}
                      updateIsGifting={props.updateIsGifting}
                      updateGiftingType={props.updateGiftingType}
                      updatePersonalDetails={props.updatePersonalDetails}
                      checkout={props.checkoutState}
                      addressValidationToken = {addressValidationToken}
                      apiUrl={apiUrl}
                      transactionTypeId={transactionTypeId}
                      setGiftType={setGiftType}
                    />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={6} floated='right'>
                    {isMobile() ?
                    <NavHeader link="/plate-owner-selection" info="Plate Owner"/>:
                    <OrderSummary isExpanded={false} isLayby={false} showSurcharge={false} basket={props.basket} apiUrl={apiUrl} giftType={giftType}/>}
                  </Grid.Column>
          </Grid>
        </MobileGenericBannerContainer>
    </Layout>);

}


const mapStateToProps = (state: Record<any>) => {
  const checkout = currentCheckoutSelector(state).toJS();
  const basket = currentBasketSelector(state).toJS();
  return {
    checkoutState: checkout,
    basket: basket
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateOwnerIsApplicant(ownerIsApplicant:boolean){
    dispatch(updateOwnerIsApplicant(ownerIsApplicant));
},
updateManufactureNow(manufactureNow:boolean){
    dispatch(updateManufactureNow(manufactureNow));
},
updateIsGifting(isGifting:boolean){
  dispatch(updateIsGifting(isGifting));
},
updateGiftingType(giftingType: number) {
  dispatch(updateGiftingType(giftingType));
},
updatePersonalDetails(personalDetails: Checkout) {
  dispatch(updatePersonalDetails(personalDetails))
}
})

const SelectGiftingTypePage = connect(mapStateToProps,mapDispatchToProps)(SelectGiftingTypePageContainer)


export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
      emailValidationToken
      addressValidationToken
      reCaptchaSiteKey
      reCaptchaSiteKeyV2
    }
  }
  allKontentItemGiftSelectionPage {
    nodes {
      elements {
        url {
          value
          resolvedUrl
        }
        heading {
          value
        }
        sub_heading {
          value
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        seo__noindex {
          value {
            codename
          }
        }
        seo__nofollow {
          value {
            codename
          }
        }
        e_card {
          linked_items {
            ... on KontentItemGiftingInfo {
              elements {
                template {
                  linked_items {
                    ... on KontentItemCheckoutGiftingOptionItem {
                      elements {
                        message {
                          value
                        }
                        declaration {
                          value
                        }
                        footer1 {
                          value
                        }
                        footer2 {
                          value
                        }
                        footer3 {
                          value
                        }
                      }
                    }
                  }
                }
                title {
                  value
                }
                icon {
                  value {
                    url
                  }
                }
                description {
                  value
                }
                pricing {
                  value
                }
                delivery_info {
                  value
                }
                front_image {
                  value {
                    url
                  }
                }
                back_image {
                  value {
                    url
                  }
                }
              }
            }
          }
        }
        giftbox {
          linked_items {
            ... on KontentItemGiftingInfo {
              elements {
                title {
                  value
                }
                icon {
                  value {
                    url
                  }
                }
                description {
                  value
                }
                pricing {
                  value
                }
                delivery_info {
                  value
                }
                front_image {
                  value {
                    url
                  }
                }
                back_image {
                  value {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
  `

export default SelectGiftingTypePage;