import theme from '@styles/theme';
import React, { useState } from 'react';
import { ImageText } from './GiftImageFlipCard.style';

interface GiftImageFlipCardProps {
  frontImage: React.ReactNode;
  backImage: React.ReactNode;
  frontText: string;
  backText: string;
}

export const GiftImageFlipCard: React.FC<GiftImageFlipCardProps> = ({
  frontImage,
  backImage,
  frontText,
  backText,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div onClick={handleFlip} style={{ cursor: 'pointer' }}>
      {isFlipped ? (
        <>
          {backImage}
          <div style={{textAlign: "right", fontWeight: 'bold', color: theme.brand.colors.green, verticalAlign: 'top'}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M1.57467 8.49202C2.07932 8.49649 2.50337 8.87242 2.56831 9.37289C3.04909 12.6578 6.10182 14.931 9.38675 14.4502C12.6717 13.9695 14.9449 10.9167 14.4641 7.63179C13.9833 4.34686 10.9306 2.07365 7.64566 2.55444C6.52593 2.71832 5.47521 3.19494 4.61432 3.92951L5.36841 4.6836C5.62898 4.94423 5.62892 5.36678 5.36829 5.62735C5.24269 5.75291 5.07221 5.82323 4.89463 5.82273H1.83427C1.46572 5.82273 1.16695 5.52396 1.16695 5.15541V2.09505C1.16705 1.72649 1.46587 1.42779 1.83442 1.42786C2.01132 1.42789 2.18098 1.49817 2.30608 1.62324L3.19495 2.51211C6.50194 -0.417779 11.558 -0.112102 14.4879 3.19491C17.4178 6.50193 17.1121 11.558 13.8051 14.4879C10.4981 17.4178 5.44205 17.1121 2.51215 13.8051C1.48076 12.6409 0.811069 11.2013 0.585033 9.66252C0.496571 9.1116 0.871473 8.59324 1.42239 8.50478C1.47275 8.49668 1.52365 8.49242 1.57467 8.49202Z" fill="#3DAE2B"/>
          </svg>
            <ImageText>{backText}</ImageText></div>
        </>
      ) : (
        <>
          {frontImage}
          <div style={{textAlign: "right", fontWeight: 'bold', color: theme.brand.colors.green, verticalAlign: 'top'}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
            <g clip-path="url(#clip0_430_4418)">
            <path d="M9.49995 1.98779C6.97495 1.98779 4.95308 3.13779 3.4812 4.50654C2.0187 5.86279 1.04058 7.48779 0.578076 8.60342C0.474951 8.85029 0.474951 9.12529 0.578076 9.37217C1.04058 10.4878 2.0187 12.1128 3.4812 13.469C4.95308 14.8378 6.97495 15.9878 9.49995 15.9878C12.025 15.9878 14.0468 14.8378 15.5187 13.469C16.9812 12.1097 17.9593 10.4878 18.4249 9.37217C18.5281 9.12529 18.5281 8.85029 18.4249 8.60342C17.9593 7.48779 16.9812 5.86279 15.5187 4.50654C14.0468 3.13779 12.025 1.98779 9.49995 1.98779ZM4.99995 8.98779C4.99995 7.79432 5.47406 6.64973 6.31797 5.80581C7.16188 4.9619 8.30648 4.48779 9.49995 4.48779C10.6934 4.48779 11.838 4.9619 12.6819 5.80581C13.5258 6.64973 14 7.79432 14 8.98779C14 10.1813 13.5258 11.3259 12.6819 12.1698C11.838 13.0137 10.6934 13.4878 9.49995 13.4878C8.30648 13.4878 7.16188 13.0137 6.31797 12.1698C5.47406 11.3259 4.99995 10.1813 4.99995 8.98779ZM9.49995 6.98779C9.49995 8.09092 8.60308 8.98779 7.49995 8.98779C7.27808 8.98779 7.06558 8.95029 6.86558 8.88467C6.6937 8.82842 6.4937 8.93467 6.49995 9.11592C6.50933 9.33154 6.54058 9.54717 6.59995 9.76279C7.02808 11.3628 8.67495 12.3128 10.275 11.8847C11.875 11.4565 12.825 9.80967 12.3968 8.20967C12.05 6.91279 10.9031 6.04092 9.62808 5.98779C9.44683 5.98154 9.34058 6.17842 9.39683 6.35342C9.46245 6.55342 9.49995 6.76592 9.49995 6.98779Z" fill="#3DAE2B"/>
            </g>
            <defs>
            <clipPath id="clip0_430_4418">
            <rect width="18" height="16" fill="white" transform="translate(0.5 0.987793)"/>
            </clipPath>
            </defs>
          </svg>
            <ImageText>{frontText}</ImageText></div>
        </>
      )}
    </div>
  );
};
