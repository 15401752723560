import React, { useState, ReactNode, useRef, useEffect } from "react";

interface PopupProps {
  children: ReactNode; // Content to display in the popup
  trigger: ReactNode; // Element to trigger the popup
}

const PopupExample: React.FC<PopupProps> = ({ children, trigger }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: "20px", left: "0" });
  const triggerRef = useRef<HTMLSpanElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  // Calculate popup position when it becomes visible
  useEffect(() => {
    if (showPopup && triggerRef.current && popupRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      // Check if popup would overflow on the right
      const wouldOverflowRight = triggerRect.left + popupRect.width > windowWidth;

      // Calculate new position
      const newPosition = {
        top: "20px",
        left: wouldOverflowRight 
          ? `${-popupRect.width + triggerRect.width}px` 
          : "0"
      };

      setPopupPosition(newPosition);
    }
  }, [showPopup]);

  const handleMouseEnter = () => {
    setShowPopup(true); // Show popup on hover
  };

  const handleMouseLeave = () => {
    setShowPopup(false); // Hide popup when mouse leaves
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* Trigger Element */}
      <span
        ref={triggerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: "pointer" }}
      >
        {trigger} {/* Render the trigger element */}
      </span>

      {/* Popup */}
      {showPopup && (
        <div
          ref={popupRef}
          onMouseEnter={handleMouseEnter}  // Keep popup visible
          onMouseLeave={handleMouseLeave}  // Hide when mouse leaves
          style={{
            position: "absolute",
            top: popupPosition.top,
            left: popupPosition.left,
            border: "1px solid #ccc",
            backgroundColor: "white",
            width: "216px",
            padding: "18px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            zIndex: 1
          }}
        >
          {children} {/* Render the content passed as children */}
        </div>
      )}
    </div>
  );
};

export default PopupExample;
