import styled from "@emotion/styled";
import mq from "@styles/mq";
import theme from "@styles/theme";
import { Button, Form, Grid } from "semantic-ui-react";

export const GiftToggleButton = styled(Button)`  

`
export const ButtonToggler = styled.div`
    div {
        background: #fff !important;
        color: #000 !important;
        padding: 16px 40px !important;
        
    }
    .left.attached.button{
        border-radius: 10px 0px 0px 10px !important;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .left.attached.button.active{
        border-radius: 10px !important;
    }
    .right.attached.button {
        border-radius: 0px 10px 10px 0px !important;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .right.attached.button.active{
        border-radius: 10px !important;
    }
    .active {
        background: #000 !important;
        color: #fff !important;
    }

`

export const GiftingStepsTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 30px;
`
export const GiftingStepItemTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 10px;
`
export const GiftingOptionsTitle = styled.div`
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 16px;
`
export const GiftingExploreDesignsTitle = styled.div`
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 56px;
`
export const CardWraper = styled.div`
    margin-top: 10px;
    text-align: left;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
`
export const GiftTypeLeftColumn = styled(Grid.Column)`
    ${mq({
        paddingRight: ['6px !important', '12px !important', '12px !important'],
    })};
`
export const GiftTypeRightColumn = styled(Grid.Column)`
    ${mq({
        paddingLeft: ['6px !important', '12px !important', '12px !important'],
    })};
`
export const AddressToggerLink = styled.span`
  cursor: pointer;
  text-align: right;
  float: right;
  color: ${theme.brand.colors.green} !important;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 19px;
  padding-left: 5px;
  &:hover {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }
  `
  export const PersonalDetailsManualAddressContainer = styled.div`
  padding: 16px 0;
  ${mq({
    marginTop: ['10px', '24px', '24px'],
  })};
  border-top: 2px solid #707070;
`
export const AddressFinderFormGroup = styled(({ show, ...others }) => (
    <Form.Group {...others} />
  ))`
    display: ${props => (props.show ? "block" : "none !important")};
    ${mq({
        paddingTop: ['12px', '16px', '16px'],
        marginTop:['10px !important', '20px !important', '20px !important'],
        marginBottom:['','26px !important;','26px !important;']
      })};
    border-top: 2px solid #707070;
  `