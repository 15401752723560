import { PlateImage } from "@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles"
import { getCurrentBasket } from "@redux/localStorage/basket"
import { buildPlateImageUrlFromBasket, getFrontPlateWidth } from "@utils/Helpers"
import React from "react"
import { Divider, Form, Grid, Input, Responsive, } from "semantic-ui-react"
import { Checkout } from "@models/Checkout/Checkout"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { Formik } from "formik"
import { connect as formikConnect } from "formik"
import { KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { navigate } from "gatsby"
import { CheckoutSelectionCardModel } from "@models/Checkout/CheckoutSelectionCard"
import { CheckoutSelectionCard } from "@components/CheckoutSelectionCard"
import { CheckoutGiftingCardModel } from "@components/Mobile/GiftingSelection/mapper"
import { giftingOptionValidationSchema } from "@components/Mobile/GiftingSelection/GiftingOption.validationSchema"
import { EcardContainerWrap, EcardContainerWrapInner } from "@components/Mobile/GiftingSelection/GiftingCheckoutSelectionCard.styles"
import { CountsStyle, FormLabel, GiftingTemplateFooterStyle, GiftingTemplateHeaderStyle, LogoWrap, MessageStyle } from "@components/Mobile/GiftingSelection/EcardContainer.style"
import { LogoIcon } from "@components/Layout/MobileLayoutContainer.styles"
import { GiftImageFlipCard } from "./GiftImageFlipCard"
import { GiftTypeLeftColumn, GiftTypeRightColumn } from "./GiftPage.styles"

export type Props = {
    apiUrl: string
    updatePersonalDetails: (personalDetails: Checkout) => void
    checkout: Checkout
    eCardTemplate: CheckoutGiftingCardModel,
    frontImage: string
}

export type States = {
    to: string
    from: string
    message: string
    isForMe: boolean
    counts: number    
}

function OnSubmitValidationError(props: any) {
    const { callback, formik } = props
  
    const effect = () => {
      if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
        callback(formik)
      }
    }
    React.useEffect(effect, [formik.submitCount, formik.isSubmitting])
  
    return null
  }
  
export const SubmitValidationError = formikConnect(OnSubmitValidationError)

class EcardContainer extends React.Component<Props, States>{

    constructor(props: Props){
        super(props)
        this.state ={
            to: this.props.checkout.giftingDetails.to,
            from: this.props.checkout.giftingDetails.from,
            message: this.props.checkout.giftingDetails.message,
            isForMe: this.props.checkout.giftingDetails.isForMe,
            counts: this.props.checkout.giftingDetails.message.length
        }
    }

    intialStatus = {
        isSubmitting: false,
        isSubmitted: false,
      }
    
    submitForm = (values:any, actions: any) =>{
        if(this.state.from && this.state.to && this.state.message){
            this.props.updatePersonalDetails(values)

            navigate('/personal-details');
        }
        
    }

    checkFormValidation = () => {
        if(this.state.from.trim() && this.state.to.trim() && this.state.message.trim()){
            return false;
        }
        return true;
    }

    onSubmitValidationError = (event: any) => {
        console.log("error on validate submission")
        console.log(event)
    }

    selectIsForMe = (isForMe : boolean, setFieldValue: any) => {
        setFieldValue("giftingDetails.isForMe", isForMe)
        this.setState({isForMe: isForMe})
    }

    textAreaAdjust = () => {
        if(document) {
            var element = document.getElementById("giftingDetails.message")
            if(element){
                element.style.height = "1px";
                element.style.height = (5+element.scrollHeight)+"px";
            }
        }        
    }

    render() {
        var myselfCard: CheckoutSelectionCardModel = {
            body: "",
            content: "",
            heading: "Myself",
            items: [],
            icon:[]
          };
      
          var ownerCard: CheckoutSelectionCardModel = {
            body: "",
            content: "",
            heading: "Giftee (Owner)",
            items: [],
            icon:[]
          };

        const checkout = getCurrentCheckout();
        const basketLine = getCurrentBasket().basketLines[0];
        const frontPlateWidth = getFrontPlateWidth(basketLine.frontPlate.size.width_mm, basketLine.backPlate.size.width_mm);
        const frontPlateUrl = buildPlateImageUrlFromBasket(this.props.apiUrl, basketLine.frontPlate, basketLine.combinationLayout, basketLine.topCaption, basketLine.bottomCaption);

        return(
            <Formik
                initialValues={checkout}
                initialStatus={this.intialStatus}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={giftingOptionValidationSchema}
                onSubmit={(values: any, actions: any) =>
                    this.submitForm(values, actions)
                }>
            {({
                errors,
                values,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isValidating,
                validateField,
                isValid,
                dirty,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <SubmitValidationError callback={this.onSubmitValidationError} />
                    <Grid direction="row">                    
                        <Grid.Column mobile={16} tablet={16} computer={7}>
                            <GiftImageFlipCard
                                backImage = {<EcardContainerWrap>
                                    <EcardContainerWrapInner>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <PlateImage style={{borderRadius: "10px"}} src={frontPlateUrl} centered width={frontPlateWidth} alt="plate image" />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={2}>
                                                <GiftingTemplateHeaderStyle>To:</GiftingTemplateHeaderStyle>
                                            </Grid.Column>
                                            <Grid.Column width={6}>
                                                <GiftingTemplateHeaderStyle>{this.state.to}</GiftingTemplateHeaderStyle>
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                                <GiftingTemplateHeaderStyle>From: </GiftingTemplateHeaderStyle>
                                            </Grid.Column>
                                            <Grid.Column width={5}>
                                                <GiftingTemplateHeaderStyle>{this.state.from}</GiftingTemplateHeaderStyle>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Divider  style={{ color: "#fff" }}/>  
                                        <Grid.Row>
                                            <Grid.Column width={16} textAlign="center" >
                                                <div style={{whiteSpace: 'pre-wrap', wordWrap:'break-word', maxWidth:'500px'}}>{this.state.message ? this.state.message : "Your message will appear here"}</div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Divider  style={{ color: "#fff" }}/>  
                                        <Grid.Row>
                                            <Grid.Column width={16} textAlign="center">
                                                <GiftingTemplateFooterStyle><div dangerouslySetInnerHTML={{__html:this.props.eCardTemplate.declaration}}/></GiftingTemplateFooterStyle>                                
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={5} style={{paddingRight: "0px"}}>
                                                <GiftingTemplateFooterStyle>{this.props.eCardTemplate.footer1}</GiftingTemplateFooterStyle>
                                            </Grid.Column>
                                            <Grid.Column width={5} style={{paddingLeft: "0px", paddingRight: "0px"}} textAlign="center">
                                                <GiftingTemplateFooterStyle>{this.props.eCardTemplate.footer2}</GiftingTemplateFooterStyle>
                                            </Grid.Column>
                                            <Grid.Column width={6} style={{paddingLeft: "0px"}}>
                                                <GiftingTemplateFooterStyle>{this.props.eCardTemplate.footer3 + basketLine.frontPlate.rangeName}</GiftingTemplateFooterStyle>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </EcardContainerWrapInner>      
                                <LogoWrap><LogoIcon reverse={true} black={false} width={100}/></LogoWrap>          
                            </EcardContainerWrap> } 
                            frontImage = {<img src={this.props.frontImage} alt="Gift Box Certificate" style={{ width: "100%"}} />}
                            frontText = "View E-Card"
                            backText = "Return"
                            />
                        </Grid.Column>                        
                        <Grid.Column mobile={16} tablet={16} computer={9}>   
                            <React.Fragment>                                
                                <Form.Group widths="equal">
                                    <KpFormField
                                        width={7}
                                        error={
                                        errors.giftingDetails?.to &&
                                        touched.giftingDetails?.to
                                        }
                                    >
                                        <label>TO</label>
                                        {errors.giftingDetails?.to &&
                                        touched.giftingDetails?.to && (
                                            <KpFormInputErrorMessage>
                                            {errors.giftingDetails.to}
                                            </KpFormInputErrorMessage>
                                        )}
                                        <Input
                                        id="giftingDetails.to"
                                        name="giftingDetails.to"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="To"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onKeyUp={(event: { currentTarget: { value: any } })=>{this.setState({to: event.currentTarget.value})}}
                                        onBlur={handleBlur}
                                        value={values.giftingDetails.to}
                                        placeholder="Type here..."
                                        autoComplete="off"/>
                                    </KpFormField>
                                    <KpFormField
                                        width={7}
                                        error={
                                        errors.giftingDetails?.from &&
                                        touched.giftingDetails?.from
                                        }
                                    >
                                        <label>FROM</label>
                                        {errors.giftingDetails?.from &&
                                        touched.giftingDetails?.from && (
                                            <KpFormInputErrorMessage>
                                            {errors.giftingDetails.from}
                                            </KpFormInputErrorMessage>
                                        )}
                                        <input
                                        id="giftingDetails.from"
                                        name="giftingDetails.from"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="From"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={(event)=>{this.setState({from: event.currentTarget.value})}}
                                        value={values.giftingDetails.from}
                                        placeholder="Type here..."
                                        autoComplete="off"/>
                                    </KpFormField> 
                                </Form.Group>
                                <Form.Group widths="equal"> 
                                    <KpFormField
                                        error={
                                        errors.giftingDetails?.message &&
                                        touched.giftingDetails?.message
                                        }
                                    >
                                        <label>MESSAGE</label>
                                        {errors.giftingDetails?.message &&
                                        touched.giftingDetails?.message && (
                                            <KpFormInputErrorMessage>
                                            {errors.giftingDetails?.message}
                                            </KpFormInputErrorMessage>
                                        )}
                                        <textarea
                                        id="giftingDetails.message"
                                        name="giftingDetails.message"
                                        data-private="lipsum"
                                        aria-label="Message"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={(event)=>{this.setState({message: event.currentTarget.value, counts: event.currentTarget.value.length}); this.textAreaAdjust()}}
                                        value={values.giftingDetails?.message}
                                        placeholder="Type here..."
                                        autoComplete="off"/>
                                        <CountsStyle>{this.state.counts + '/500'}</CountsStyle>
                                    </KpFormField> 
                                </Form.Group>       
                                <Form.Group widths="equal">  
                                    <KpFormField
                                        width={8}
                                        error={
                                        errors.giftingDetails?.isForMe &&
                                        touched.giftingDetails?.isForMe
                                        }
                                    >
                                        <FormLabel><label>Send this e-card via email directly to:</label></FormLabel>
                                        {errors.giftingDetails?.isForMe &&
                                        touched.giftingDetails?.isForMe && (
                                            <KpFormInputErrorMessage>
                                            {errors.giftingDetails.isForMe}
                                            </KpFormInputErrorMessage>
                                        )}
                                        <Grid  stackable={false} stretched>
                                            <Grid.Row>
                                            <GiftTypeLeftColumn width={8}>
                                                <CheckoutSelectionCard card={myselfCard} onSelect={() => this.selectIsForMe(true, setFieldValue)} isSelected={this.state.isForMe} hideButton={true}/>
                                            </GiftTypeLeftColumn>
                                            <GiftTypeRightColumn width={8}>
                                                <CheckoutSelectionCard card={ownerCard} onSelect={() => this.selectIsForMe(false, setFieldValue)} isSelected={!this.state.isForMe} hideButton={true}/>
                                            </GiftTypeRightColumn>      
                                            </Grid.Row>
                                        </Grid>                                          
                                    </KpFormField>
                                </Form.Group>   
                            </React.Fragment>
                        </Grid.Column>
                    </Grid>   
                    <Grid.Row verticalAlign='bottom'>
                        <Responsive minWidth={theme.responsive.tablet.minWidth}>
                            <Grid.Column width={16}>
                                <Grid reversed='mobile vertically'>                                    
                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <KpButton id="pos-back" fullWidth="mobile"  buttonType='secondary' color={theme.brand.colors.black} link='/plate-owner-selection/'>BACK</KpButton>
                                    </Grid.Column>                                    
                                    <Grid.Column mobile={16} tablet={8} computer={8} textAlign='right'>
                                    <KpButton id="pos-continue" fullWidth="mobile"  buttonType='primary' color={theme.brand.colors.blue} type="submit" disabled={this.checkFormValidation()}>CONTINUE</KpButton>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Responsive>
                        <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                            <Grid.Column width={16}>
                                <Grid reversed='mobile vertically'> 
                                    <Grid.Column mobile={16} textAlign='right'>
                                        <KpButton id="pos-continue" fullWidth="mobile"  buttonType='primary' color={theme.brand.colors.blue} type="submit" disabled={this.checkFormValidation()}>CONTINUE</KpButton>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Responsive>
                    </Grid.Row>                 
                </Form>
                )}
            </Formik>
        )
    }
    
}

export default EcardContainer