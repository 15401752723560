import React from 'react'
import { H2, H5 } from '@styles/Global.styles'
import { Divider, Grid, Icon, Popup, Responsive } from 'semantic-ui-react'
import theme from '@styles/theme'
import { Checkout } from '@models/Checkout/Checkout'
import { CheckoutSelectionCardContainer, CheckoutSelectionCardHeading, CheckoutSelectionCardIndicator } from '@components/CheckoutSelectionCard/CheckoutSelectionCard.styles'
import EcardContainer from '@components/GiftingSelection/EcardContainer'
import { navigate } from 'gatsby'
import GiftboxContainer from '@components/GiftingSelection/GiftboxContainer'
import { GiftInfo } from '@mappers/Gifting/Gfiting.mapper'
import { GiftAPlateSubtitle, GiftAPlateTitle, GiftCardTitle, GiftDelivery, GiftPrice, GiftTypeContainer, GiftTypeLeftColumn, GiftTypeRightColumn, TitleRow } from './GiftType.styles'
import PopupExample from './Popup'

export type GiftTypeSelectionProps = {
    heading: string
    subHeading: string
    giftBoxInfo: GiftInfo
    eCardInfo: GiftInfo
    updateOwnerIsApplicant: (ownerIsApplicant:boolean) => void
    updateManufactureNow: (manufactureNow:boolean)=>void
    updateIsGifting: (isGifting: boolean) => void
    updateGiftingType: (giftingType: number) => void
    updatePersonalDetails: (personalDetails: Checkout) => void
    checkout: Checkout
    apiUrl: string
    transactionTypeId: number
    addressValidationToken: string
    setGiftType: (giftType: number) => void
}

interface States {
    // giftingType : number
    // isSelected : boolean,
    // giftingSelected: boolean
}

class GiftTypeSelection extends React.Component<GiftTypeSelectionProps, States>{


    selectGiftTypeOption = (giftingType: number) => {
        setTimeout(()=>{
            console.log("giftingType updated:", giftingType)
            this.props.updateGiftingType(giftingType)
            this.props.setGiftType(giftingType)
        },0)
    }

    continue= () =>{
        if(this.props.checkout.giftingType !== 0 && this.props.checkout.isGifting){
            navigate('/select-gifting-type/')
        }
        if(this.props.checkout.ownerIsApplicant && !this.props.checkout.isLayby) {
            navigate('/plate-manufacture-selection/')
        } else{
            navigate('/personal-details/')
        }
    }

    render(){

        const {heading, subHeading, giftBoxInfo, eCardInfo, checkout} = this.props;

        return (
          <Grid>
            <TitleRow>
              <Grid.Column width={16}>
                <GiftAPlateTitle>{heading}</GiftAPlateTitle>
                <GiftAPlateSubtitle>{subHeading}</GiftAPlateSubtitle>
              </Grid.Column>
            </TitleRow>
            <Grid.Row>
              <GiftTypeLeftColumn
                mobile={8}
                tablet={8}
                computer={7}
                style={{ marginBottom: "2px" }}
              >
                <CheckoutSelectionCardContainer
                  className={checkout.giftingType == 2 ? "selected" : ""}
                  onClick={() => this.selectGiftTypeOption(2)}
                >
                  <div style={{ marginBottom: "2px" }}>
                    <CheckoutSelectionCardHeading>
                      <img src={giftBoxInfo.icon} alt="Kiwiplates - Gift Box" />
                      <GiftCardTitle>
                        {giftBoxInfo.title}
                      </GiftCardTitle>
                    </CheckoutSelectionCardHeading>
                    <Responsive minWidth={theme.responsive.tablet.minWidth}>
                      {giftBoxInfo.description && (
                        <span>{giftBoxInfo.description}</span>
                      )} 
                      <div style={{display: "inline-block", marginLeft: "10px"}}>
                        <PopupExample trigger={<Icon name="question circle outline" color="orange" size="small"/>}>
                        <div>
                          <div style={{fontSize: "14px", fontWeight: "bold", paddingBottom: "12px"}}>Gift Box Details</div>
                          <div style={{paddingBottom: "12px", color: theme.brand.colors.darkGrey, fontSize: "12px"}}>Includes the Right to Receive Certificate, Gift Certificate and Manufacture instructions. <a href="/gift/" target="_blank" style={{color: theme.brand.colors.green}}>View more info <Icon name="external" size="small" /></a></div>
                        </div>
                        </PopupExample>
                    </div>
                    </Responsive>
                  </div>                  
                  {giftBoxInfo.pricing && (
                    <GiftPrice>
                      {giftBoxInfo.pricing}
                    </GiftPrice>
                  )}                  
                  {giftBoxInfo.deliveryInfo && (
                    <GiftDelivery>{giftBoxInfo.deliveryInfo}</GiftDelivery>
                  )}                  
                  {checkout.giftingType == 2 && (
                    <CheckoutSelectionCardIndicator>
                      <Icon name="check" size="small" />
                    </CheckoutSelectionCardIndicator>
                  )}
                </CheckoutSelectionCardContainer>
              </GiftTypeLeftColumn>
              <GiftTypeRightColumn
                mobile={8}
                tablet={8}
                computer={7}
                style={{ marginBottom: "2px" }}
              >
                <CheckoutSelectionCardContainer
                  className={checkout.giftingType == 1 ? "selected" : ""}
                  onClick={() => this.selectGiftTypeOption(1)}
                >
                  <div style={{ marginBottom: "2px" }}>
                    <CheckoutSelectionCardHeading>
                      <img src={eCardInfo.icon} alt="Kiwiplates - E-Card" />
                      <GiftCardTitle>
                        {eCardInfo.title}
                      </GiftCardTitle>
                    </CheckoutSelectionCardHeading>
                    <Responsive minWidth={theme.responsive.tablet.minWidth}>
                      {eCardInfo.description && (
                        <span>{eCardInfo.description}</span>
                      )}
                    </Responsive>
                  </div>                  
                  {eCardInfo.pricing && (
                    <GiftPrice>
                      {eCardInfo.pricing}
                    </GiftPrice>
                  )}                
                  {eCardInfo.deliveryInfo && (
                    <GiftDelivery>
                      {eCardInfo.deliveryInfo}
                    </GiftDelivery>
                  )}                  
                  {checkout.giftingType == 1 && (
                    <CheckoutSelectionCardIndicator>
                      <Icon name="check" size="small" />
                    </CheckoutSelectionCardIndicator>
                  )}
                </CheckoutSelectionCardContainer>
              </GiftTypeRightColumn>
            </Grid.Row>
            <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
              {checkout.giftingType == 2 && giftBoxInfo.description && (
                <div>
                  <span>{giftBoxInfo.description}</span>
                  
                  <div style={{display: "inline-block", marginLeft: "10px"}}>
                        <PopupExample trigger={<Icon name="question circle outline" color="orange" size="small"/>}>
                        <div>
                          <div style={{fontSize: "14px", fontWeight: "bold", paddingBottom: "12px"}}>Gift Box Details</div>
                          <div style={{paddingBottom: "12px", color: theme.brand.colors.darkGrey, fontSize: "12px"}}>Includes the Right to Receive Certificate, Gift Certificate and Manufacture instructions. <a href="/gift/" target="_blank" style={{color: theme.brand.colors.green}}>View more info <Icon name="external" size="small" /></a></div>
                        </div>
                        </PopupExample>
                    </div>
                </div>
              )}
              {checkout.giftingType == 1 && eCardInfo.description && (
                <div>
                  <span>{eCardInfo.description}</span>
                </div>
              )}
            </Responsive>
            {this.props.checkout.giftingType == 1 && eCardInfo.template && (
              <GiftTypeContainer>
                <Divider/>
                <EcardContainer
                  updatePersonalDetails={this.props.updatePersonalDetails}
                  apiUrl={this.props.apiUrl}
                  checkout={this.props.checkout}
                  eCardTemplate={eCardInfo.template}
                  frontImage={eCardInfo.frontImage}
                />
              </GiftTypeContainer>
            )}
            {this.props.checkout.giftingType == 2 && (
              <GiftTypeContainer>
                <Divider className='gift-type-divider'/>
                <GiftboxContainer
                  updatePersonalDetails={this.props.updatePersonalDetails}
                  apiUrl={this.props.apiUrl}
                  checkout={this.props.checkout}
                  addressValidationToken={this.props.addressValidationToken}
                  frontImage={giftBoxInfo.frontImage}
                  backImage={giftBoxInfo.backImage}
                />
              </GiftTypeContainer>
            )}
            {/* <Grid.Row verticalAlign='bottom'>
                <Grid.Column width={16}>
                <Grid reversed='mobile vertically'>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                    <KpButton id="pos-back" fullWidth="mobile"  buttonType='secondary' color={theme.brand.colors.black} link='/shopping-cart'>BACK Home</KpButton>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} textAlign='right'>
                    <KpButton id="pos-continue" fullWidth="mobile"  buttonType='primary' color={theme.brand.colors.blue} onClick={() => this.continue()} >CONTINUE</KpButton>
                    </Grid.Column>
                </Grid>
                </Grid.Column>
            </Grid.Row> */}
          </Grid>
        )
    }
}
export default GiftTypeSelection