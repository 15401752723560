import { calculatePlateMetaInfo, mapToProductModelCategoryList } from "@mappers/ProductModels/mapper";
import { GiftingExploreDesignsProps, GiftingOptionsProps, GiftingStepsProps } from "@models/Gifting/Gifting.models";
import { PlateCategoryMetaInfo, ProductModelCategoryList, VehicleTypesSetting } from "@models/ProductModels";
import { convertKontentColorToHex } from "@utils/Helpers";

export const mapToGiftingStepsData = (data: any): GiftingStepsProps => {
    
    const steps = data.steps.linked_items.map((step: any) => {
        return {
            description: step.elements.description.value,
            icon: {
                url: step.elements.icon.value[0].url,
                description: step.elements.icon.value[0].description
            },
            title: step.elements.title.value,
        }
    })

    return {
        title: data.title.value,
        steps: steps,
        backgroundColor: convertKontentColorToHex(data.background_colors.value[0].codename)
    }
}

export const mapToGiftingOptionsData = (data: any): GiftingOptionsProps => {
    
    const giftingOptions = data.gifting_options.linked_items.map((option: any) => {
        return {
            picture: {
                url: option.elements.picture.value[0].url,
                description: option.elements.picture.value[0].description
            },
            item :{
                heading: option.elements.item.linked_items[0].elements.heading.value,
                body: option.elements.item.linked_items[0].elements.body.value,
                content: option.elements.item.linked_items[0].elements.content.value,
                items: null
            }
        }
    })

    return {
        title: data.title.value,
        description: data.description.value,
        giftingOptions: giftingOptions,
        backgroundColor: convertKontentColorToHex(data.background_colors.value[0].codename)
    }
}

export const mapToGiftingExploreDesignsData = (data : any, apiUrl: string): GiftingExploreDesignsProps =>{
    const list = mapToPlateDesignList(data.items.linked_items);
    return {
        title: data.title.value,
        backgroundColor: convertKontentColorToHex(data.background_colors.value[0].codename),
        giftingExploreDesigns: list,
        apiUrl: apiUrl
    }
}

export const mapToPlateDesignList = (els: Array<any>) => {
    const vt = VehicleTypesSetting.Car;
    let result: { metaInfo: PlateCategoryMetaInfo, products: ProductModelCategoryList}[] = [];
    els.forEach((element) => {     
        const metaInfo: PlateCategoryMetaInfo = calculatePlateMetaInfo(element.elements, "PLATES");
        const products = mapToProductModelCategoryList(metaInfo.name, element.elements.product_designs.linked_items, vt);
        result.push({
            metaInfo: metaInfo,
            products: products,
        });
    })       
    return result;
}

export type GiftInfo = {
    title: string;
    description: string;
    icon: string;
    pricing: string;
    deliveryInfo: string;
    frontImage: string;
    backImage: string;
    template?: {
        message: string;
        declaration: string;
        footer1: string;
        footer2: string;
        footer3: string;
    }
};

export const mapToGiftInfo = (data: any): GiftInfo => {
    let template = undefined;
    if(data.template && data.template.linked_items && data.template.linked_items.length > 0){
        const {message, declaration, footer1, footer2, footer3} = data.template.linked_items[0].elements;
        template = {
            message: message.value,
            declaration: declaration.value,
            footer1: footer1.value,
            footer2: footer2.value,
            footer3: footer3.value
        }
    }
    return {
        title: data.title.value,
        description: data.description.value,
        icon: data.icon.value[0].url,
        pricing: data.pricing.value,
        deliveryInfo: data.delivery_info.value,
        frontImage: data.front_image.value[0].url,
        backImage: data.back_image.value[0]?.url,
        template: template
    }
}
